@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

*,
*:before,
*:after {
  @apply antialiased;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.select__input:focus {
  box-shadow: none;
}

#nprogress .spinner {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
}

#nprogress .spinner-icon {
  width: 30px;
  height: 30px;
  box-sizing: border-box;

  border: solid 4px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
