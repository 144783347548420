
.trigger:hover .tooltip{
  display: block;
}

.trigger:disabled {
  pointer-events: visible;
  cursor: not-allowed;
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, .7);
  color: #FFF;
  padding: 5px 10px;
  font-size: 12px;
  width: max-content;
  top: 100%;
  transform: translateX(-50%);
  left: 50%;
  border-radius: 5px;
  display: none;
  z-index: 999;
  margin-top: 10px;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -8px;
  width: 0; height: 0;
  border-bottom: 8px solid rgba(0, 0, 0, .7);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  z-index: 999;
}